import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
  Button,
  Input,
  Badge,
} from "@material-tailwind/react";
import { useState } from "react";
import toast from "react-hot-toast";
import { MemberServices } from "src/services/MemberServices";

type subscriptions = {
  product: {
    id: number;
    name: string;
  };
  expired_at: string;
};

export function CheckMemberPage() {
  const namaBulan = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const memberServices = new MemberServices();
  const [cardNumber, setCardNumber] = useState("");

  const [information, setInformation] = useState({
    cardNumber: "",
    email: "",
    name: "",
    expiredAt: "",
    phone: "",
    profilePic: "",
    subscriptions: [] as subscriptions[],
  });

  const handleCheckMember = async (e: any) => {
    e.preventDefault();

    const res = await memberServices.getMemberCard({ id: cardNumber });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      setInformation({
        cardNumber: res.card_number,
        email: res.email,
        name: res.name,
        expiredAt: res.expired_at,
        phone: res.phone,
        profilePic: res.profile_pic,
        subscriptions: res.subscriptions,
      });
    }
  };

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12 min-h-[calc(100vh-200px)]">
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-6">
          <Card>
            <CardHeader
              variant="gradient"
              color="gray"
              className="mb-0 p-6 flex items-center justify-between"
            >
              <Typography variant="h6" color="white">
                Member Check
              </Typography>
            </CardHeader>
            <CardBody className="">
              <form className="mb-2" onSubmit={handleCheckMember}>
                <div className="mb-1 flex flex-col gap-5 lg:col-span-1 col-span-2">
                  <Input
                    name="memberCardNumber"
                    value={cardNumber}
                    crossOrigin={cardNumber.toString()}
                    label="Card Number"
                    onChange={(e) => {
                      setCardNumber(e.target.value);
                    }}
                    autoFocus={true}
                  />
                </div>

                <div className="flex mt-8 gap-4">
                  <Button size="lg" type="submit">
                    Check
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
          {information.cardNumber != "" ? (
            <Card className="mt-16">
              <CardHeader
                variant="gradient"
                color="gray"
                className="p-6 flex items-center justify-between"
              >
                <Typography variant="h6" color="white">
                  Member Detail
                </Typography>
              </CardHeader>
              <CardBody>
                <div className="flex flex-col gap-2">
                  <div className="flex justify-center mt-2 mb-4">
                    <Avatar src={information.profilePic} size="xxl" />
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-3">
                      <h5 className="f-h5">Card Number</h5>
                    </div>
                    <div className="col-span-9">
                      <h5 className="f-p1-r">{information.cardNumber}</h5>
                    </div>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-3">
                      <h5 className="f-h5">Name</h5>
                    </div>
                    <div className="col-span-9">
                      <h5 className="f-p1-r">{information.name}</h5>
                    </div>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-3">
                      <h5 className="f-h5">Email</h5>
                    </div>
                    <div className="col-span-9">
                      <h5 className="f-p1-r">{information.email}</h5>
                    </div>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-3">
                      <h5 className="f-h5">Phone</h5>
                    </div>
                    <div className="col-span-9">
                      <h5 className="f-p1-r">{information.phone}</h5>
                    </div>
                  </div>

                  {/* <div className="grid grid-cols-12">
                    <div className="col-span-3">
                      <h5 className="f-h5">Expired At</h5>
                    </div>
                    <div className="col-span-9">
                      <h5 className="f-p1-r">
                        {new Date(information.expiredAt).toLocaleDateString()}
                        {" | "}
                        {new Date(information.expiredAt).toLocaleTimeString()}
                      </h5>
                    </div>
                  </div> */}
                </div>
              </CardBody>
            </Card>
          ) : null}
        </div>

        <div className="col-span-6">
          {information.subscriptions.length > 0 && (
            <Card className="mt-0">
              <CardHeader
                variant="gradient"
                color="gray"
                className="p-6 flex items-center justify-between"
              >
                <Typography variant="h6" color="white">
                  Member Subscription
                </Typography>
              </CardHeader>
              <CardBody>
                {information.subscriptions.map((item, index) => {
                  const expired_at = new Date(item.expired_at);

                  const tahun = expired_at.getFullYear();
                  const bulan = expired_at.getMonth() + 1;
                  const tanggal = expired_at.getDate();
                  const jam = expired_at.getHours();
                  const menit = expired_at.getMinutes();
                  const detik = expired_at.getSeconds();

                  return (
                    <div className="flex flex-col gap-2 mb-2">
                      <div className="grid grid-cols-12">
                        <div className="col-span-3">
                          <h5 className="f-h5">Id</h5>
                        </div>
                        <div className="col-span-9">
                          <h5 className="f-p1-r">{item.product.id}</h5>
                        </div>
                      </div>
                      <div className="grid grid-cols-12">
                        <div className="col-span-3">
                          <h5 className="f-h5">Name</h5>
                        </div>
                        <div className="col-span-9">
                          <h5 className="f-p1-r">{item.product.name}</h5>
                        </div>
                      </div>

                      <div className="grid grid-cols-12">
                        <div className="col-span-3">
                          <h5 className="f-h5">Expired At</h5>
                        </div>
                        <div className="col-span-9">
                          <div
                            className={`${
                              (new Date(item.expired_at).getTime() -
                                new Date().getTime() <
                                3 * 24 * 60 * 60 * 1000 &&
                                "bg-orange-500") ||
                              (new Date(item.expired_at).getTime() -
                                new Date().getTime() <
                                0 &&
                                "bg-red-500") ||
                              "bg-green-500"
                            } text-white py-1 px-5 w-fit rounded-full`}
                          >
                            <h5 className="f-p1-r">
                              {jam + ":" + menit + ":" + detik}
                              {" | "}
                              {tanggal +
                                " " +
                                namaBulan[bulan - 1] +
                                " " +
                                tahun}
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div className="h-0.5 bg-gray-800 my-4 rounded-full"></div>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
}
