import { createContext, useState } from "react";
import { Cart, ProductCart } from "src/types/init";

interface CartContextType {
  cart: Cart;
  addToCart: (product: ProductCart) => void;
  removeFromCart: (productCartId: string) => void;
  updateCart: (productCartId: string, qty: number, note: string) => void;
}

export const CartContext = createContext<CartContextType>({
  cart: { products: [] },
  addToCart: () => {},
  removeFromCart: () => {},
  updateCart: () => {},
});

interface Props {
  children: React.ReactElement;
}

export const CartProvider: React.FC<Props> = ({
  children,
}): React.ReactElement => {
  const [cart, setCart] = useState<Cart>({ products: [] });

  function addToCart(product: ProductCart) {
    const tempCart = cart.products;
    tempCart.push(product);
    setCart({ products: tempCart });
  }

  function removeFromCart(productCartId: string) {
    const tempCart = cart.products.filter((item) => item.id !== productCartId);
    setCart({ products: tempCart });
  }

  function updateCart(productCartId: string, qty: number, note: string) {
    const tempCart = cart.products.map((item) => {
      if (item.id === productCartId) {
        item.qty = qty;
        item.note = note;
      }
      return item;
    });
    setCart({ products: tempCart });
  }

  return (
    <CartContext.Provider
      value={{ addToCart, removeFromCart, updateCart, cart }}
    >
      {children}
    </CartContext.Provider>
  );
};
