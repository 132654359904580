import { Route, Routes } from "react-router-dom";
import { MenuListPage } from "./list";
import { MenuCartPage } from "./cart";
import { MenuInformationPage } from "./information";
import { MenuPaymentPage } from "./payment";

export function MenuRouter() {
  return (
    <div className="mt-0 min-h-[calc(100vh-160px)]">
      <Routes>
        <Route path="/" element={<MenuListPage />} />
        <Route path="/cart" element={<MenuCartPage />} />
        <Route path="/information" element={<MenuInformationPage />} />
        <Route path="/payment" element={<MenuPaymentPage />} />
      </Routes>
    </div>
  );
}
