import { Button, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { MemberServices } from "src/services/MemberServices";
import { InputDefaultComponent } from "./component/InputDefaultComponent";
import { InputImage } from "./component/input-image";

export function MemberEditCardPage() {
  const navigate = useNavigate();
  const { id, cardNumber } = useParams();

  const memberServices = new MemberServices();

  const [newCardNumber, setNewCardNumber] = useState("");

  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    setNewCardNumber(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();

    const res = await memberServices.updateCard({
      cardNumber: cardNumber || "",
      newCardNumber: newCardNumber,
    });

    setLoading(false);

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success edit card member");
      navigate("/member");
    }
  };

  return (
    <div className="flex flex-col gap-12 min-h-[calc(100vh-200px)]">
      <div className="bg-white shadow-lg rounded-xl p-10">
        <Typography variant="h6" color="gray">
          Edit Card Member
        </Typography>
        <form className="mt-8 mb-2" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 lg:gap-10 gap-5">
            <div className="mb-1 flex flex-col gap-5 lg:col-span-1 col-span-2">
              <InputDefaultComponent
                label="Card Number"
                name="cardNumber"
                value={cardNumber ?? ""}
                handleChange={() => {}}
              />

              <InputDefaultComponent
                label="New Card Number"
                name="newCardNumber"
                value={newCardNumber}
                handleChange={handleChange}
              />
            </div>
          </div>

          <div className="flex mt-8 gap-4">
            <Button size="lg" type="submit">
              Submit
            </Button>

            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
