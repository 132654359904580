import { REACT_APP_API_KEY, REACT_APP_API_URL } from "src/configs/url";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";
import axios from "axios";
import { headers, headersApiKey } from "./config";

export class TransactionServices {
  async isTransactionSettled({ id }: { id: string }) {
    try {
      const res = await axios.post(
        REACT_APP_API_URL,
        {
          query: `
                query Query($isTransactionSettledId: ID!) {
                  isTransactionSettled(id: $isTransactionSettledId)
                }`,
          variables: {
            isTransactionSettledId: id,
          },
        },
        {
          headers: headersApiKey,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return;
      }
    } catch (err) {
      handleAxiosError(err);
      return;
    }
  }
}
