import { createContext, useState } from "react";
import { User } from "src/types/init";
import React from "react";

interface UserContextType {
  initUser: (user: User) => void;
  editUser: (updatedUser: User) => void;
  user: User;
}

export const UserContext = createContext<UserContextType>({
  initUser: (user: User) => {},
  editUser: (updatedUser: User) => {},
  user: { name: "", email: "", userRole: "" } as User,
});

interface Props {
  children: React.ReactElement;
}

export const UserProvider: React.FC<Props> = ({
  children,
}): React.ReactElement => {
  const [user, setUser] = useState<User>({
    name: "",
    email: "",
    userRole: "",
  } as User);

  const initUser = (user: User) => {
    setUser(user);
  };

  const editUser = (updatedUser: User) => {
    setUser({ ...updatedUser });
  };

  return (
    <UserContext.Provider value={{ initUser, editUser, user }}>
      {children}
    </UserContext.Provider>
  );
};
