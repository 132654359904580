import { apiUrl } from "src/configs/url";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";
import axios from "axios";
import { headersApiKey } from "./config";

export class PrinterServices {
  async submitPrintJob({ json, id }: { json: any; id: string }) {
    try {
      const res = await axios.post(
        apiUrl + "/self_service/printers/queues",
        {
          payload: json,
          order_customer_transaction_id: id,
        },
        {
          headers: {
            ...headersApiKey,
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return;
      }
    } catch (err) {
      handleAxiosError(err);
      return;
    }
  }
}
