import { Toaster } from "react-hot-toast";
import { Routes, Route } from "react-router-dom";
import { SignInPage } from "src/pages/auth";
import routes from "src/routes";

export function AuthLayout() {
  return (
    <div className="relative min-h-screen w-full">
      <SignInPage />
      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
}
