import { Routes, Route, Navigate } from "react-router-dom";
import { AuthLayout, DashboardLayout } from "src/layouts";
import { BrowserRouter } from "react-router-dom";
import { AppContextProvider } from "./context/AppContextProvider";
import LoadComponent from "./widgets/load";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "./context/UserContext";
import { JWTPayload } from "./utils/JWTPayload";
import { cookies } from "./services/config";
import { User } from "./types/init";
import Cookies from "js-cookie";

function App() {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <UserManager />
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;

function UserManager() {
  const { initUser, editUser, user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const payload = JWTPayload({ token: cookies?.token ?? "" });
    const nextExp = payload.exp * 1000;

    if (nextExp / 1000 < new Date().getTime() / 1000) {
      Cookies.remove("token");

      window.location.href = "/";
    }

    if (payload) {
      const temp = {
        name: payload.name,
        email: payload.email,
        userRole: payload.user_role,
      } as User;

      initUser(temp);
    }
    setLoading(false);
  }

  if (loading)
    return (
      <div className="h-screen w-full">
        <LoadComponent />
      </div>
    );
  else if (user.email == "")
    return (
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="*" element={<Navigate to="/auth/sign-in" replace />} />
      </Routes>
    );
  else if (user) {
    return (
      <Routes>
        <Route path="/dashboard/*" element={<DashboardLayout />} />
        <Route path="*" element={<Navigate to="/dashboard/menu" replace />} />
      </Routes>
    );
  } else {
    return <h1>Something Wrong!</h1>;
  }
}
