import { apiUrl } from "src/configs/url";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";
import axios from "axios";
import { headers, headersAuth, headersFormData } from "./config";

export class MemberServices {
  async registration({
    name,
    phone,
    password,
    email,
    photo,
    nationalIdNumber,
  }: {
    name: string;
    phone: string;
    password: string;
    email: string;
    photo: File | null;
    nationalIdNumber: string;
  }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("email", email);
    formData.append("photo", photo!);
    formData.append("national_id_number", nationalIdNumber);

    try {
      const res = await axios.post(`${apiUrl}/dashboard/members`, formData, {
        headers: headersFormData,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async update({
    name,
    photo,
    nationalIdNumber,
    id,
  }: {
    name: string;
    photo: any;
    nationalIdNumber: string;
    id: string;
  }) {
    const formData = new FormData();
    formData.append("name", name);
    if (photo != null) formData.append("photo", photo);
    if (nationalIdNumber != null)
      formData.append("national_id_number", nationalIdNumber);

    try {
      const res = await axios.patch(
        `${apiUrl}/dashboard/members/${id}`,
        formData,
        {
          headers: headersFormData,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async get({ id }: { id: string }) {
    try {
      const res = await axios.get(`${apiUrl}/dashboard/members/${id}`, {
        headers: headersAuth,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async gets({ limit = 20, offset = 0 }) {
    try {
      const res = await axios.get(
        `${apiUrl}/dashboard/members?limit=${limit}&offset=${offset}`,
        { headers: headersAuth }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async delete({ id }: { id: string }) {
    try {
      const res = await axios.delete(`${apiUrl}/dashboard/members/${id}`, {
        headers: headersAuth,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async addMemberCard({ cardNumber, id }: { cardNumber: string; id: string }) {
    try {
      const res = await axios.post(
        `${apiUrl}/dashboard/members/${id}/cards`,
        {
          card_number: cardNumber,
        },
        {
          headers: headersAuth,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async getMemberCard({ id }: { id: string }) {
    try {
      const res = await axios.get(`${apiUrl}/dashboard/cards/${id}`, {
        headers: headersAuth,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async updateCard({
    cardNumber,
    newCardNumber,
  }: {
    cardNumber: string;
    newCardNumber: string;
  }) {
    try {
      const res = await axios.patch(
        `${apiUrl}/dashboard/cards/${cardNumber}/cards-changes`,
        { new_card_number: newCardNumber },
        {
          headers: headers,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
