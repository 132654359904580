import { createContext, useState } from "react";
import { ProductList, User } from "src/types/init";
import React from "react";

interface ProductContextType {
  addProduct: (product: ProductList) => void;
  removeProduct: (productId: string) => void;
  products: ProductList[];
}

export const ProductContext = createContext<ProductContextType>({
  addProduct: (product) => {},
  removeProduct: (productId) => {},
  products: [] as ProductList[],
});

interface Props {
  children: React.ReactElement;
}

export const ProductProvider: React.FC<Props> = ({
  children,
}): React.ReactElement => {
  const [products, setProducts] = useState<ProductList[]>([]);

  const addProduct = (product: ProductList) => {
    // console.log("product", product);
    // setProducts([...products, product]);
  };

  const removeProduct = (productId: string) => {
    setProducts(products.filter((product) => product.id !== productId));
  };

  return (
    <ProductContext.Provider value={{ addProduct, removeProduct, products }}>
      {children}
    </ProductContext.Provider>
  );
};
