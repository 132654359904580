import { ChartBarIcon } from "@heroicons/react/24/outline";
import {
  HomeIcon,
  UserCircleIcon,
  TableCellsIcon,
} from "@heroicons/react/24/solid";
import {
  HomePage,
  ProfilePage,
  MemberRouter,
  MenuRouter,
  CheckMemberPage,
} from "src/pages/dashboard";

const icon = {
  className: "w-5 h-5 text-inherit",
};

export interface Route {
  layout?: string;
  pages: RoutePage[];
}

export interface RoutePage {
  icon: React.ReactNode; // Represent any React Node for the icon
  name: string;
  path: string;
  to: string; // Optional to property for the link
  element: React.ReactNode; // Represent any React Node for the component
  type?: "private"; // Optional type property for private routes
}

export const routes: Route[] = [
  {
    layout: "dashboard",
    pages: [
      {
        icon: <HomeIcon {...icon} />,
        name: "menu",
        path: "/menu/*",
        to: "/menu",
        element: <MenuRouter />,
      },
      {
        icon: <UserCircleIcon {...icon} />,
        name: "check member",
        path: "/check-member",
        to: "/check-member",
        element: <CheckMemberPage />,
      },
      {
        icon: <TableCellsIcon {...icon} />,
        name: "member",
        path: "/member/*",
        to: "/member",
        element: <MemberRouter />,
      },
      {
        icon: <UserCircleIcon {...icon} />,
        name: "profile",
        path: "/profile",
        to: "/profile",
        element: <ProfilePage />,
      },
    ],
  },
];

export default routes;
