import { Input, Button, Typography } from "@material-tailwind/react";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { UserContext } from "src/context/UserContext";
import { AuthServices } from "src/services/AuthServices";
import { JWTPayload } from "src/utils/JWTPayload";
import Cookies from "js-cookie";

export function SignInPage() {
  const authServices = new AuthServices();

  const { user, initUser } = useContext(UserContext);

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const res = await authServices.SignIn({ ...data });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      console.log(res);
      const payload = JWTPayload({ token: res.token });

      if (payload) {
        toast.success("Success sign in");
        Cookies.set("token", res.token);

        window.location.href = "/";
      }
    }
  };

  return (
    <section className="flex gap-4 h-screen lg:p-6">
      <div className="w-full lg:w-3/5 mt-24">
        <div className="text-center">
          <Typography variant="h2" className="font-bold mb-4">
            Sign In
          </Typography>
          <Typography
            variant="paragraph"
            color="blue-gray"
            className="text-lg font-normal"
          >
            Enter your email and password to Sign In.
          </Typography>
        </div>
        <form
          onSubmit={handleSubmit}
          className="mt-8 mb-2 mx-auto w-80 max-w-screen-lg lg:w-1/2"
        >
          <div className="mb-1 flex flex-col gap-6">
            <Typography
              variant="small"
              color="blue-gray"
              className="-mb-3 font-medium"
            >
              Your email
            </Typography>
            <Input
              crossOrigin={"true"}
              placeholder="name@mail.com"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              name="email"
              onChange={handleChange}
              value={data.email}
            />
            <Typography
              variant="small"
              color="blue-gray"
              className="-mb-3 font-medium"
            >
              Password
            </Typography>
            <Input
              crossOrigin={"true"}
              type="password"
              size="lg"
              placeholder="********"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              name="password"
              onChange={handleChange}
              value={data.password}
            />
          </div>

          <Button className="mt-6" fullWidth type="submit">
            Sign In
          </Button>
        </form>
      </div>
      <div className="w-2/5 h-full hidden lg:flex">
        <img
          src="/img/pattern.png"
          className="h-[calc(100vh-4rem)] w-full object-cover rounded-3xl my-auto"
        />
      </div>
    </section>
  );
}
