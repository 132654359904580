import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { MemberServices } from "src/services/MemberServices";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
  Button,
} from "@material-tailwind/react";
import { InputDefaultComponent } from "./component/InputDefaultComponent";
import { FiEdit } from "react-icons/fi";

export function MemberDetailPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const memberServices = new MemberServices();

  const [member, setMember] = useState({
    id: "",
    name: "",
    email: "",
    memberCards: [],
  });

  const [newCard, setNewCard] = useState({
    cardNumber: "",
  });

  useEffect(() => {
    fetchMember();
  }, []);

  async function fetchMember() {
    const res = await memberServices.get({ id: id! });

    setMember({
      id: res.id,
      name: res.name,
      email: res.email,
      memberCards: res.member_cards,
    });
  }

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (newCard.cardNumber === "") {
      toast.error("Card number is required");
      return;
    }

    const res = await memberServices.addMemberCard({
      cardNumber: newCard.cardNumber,
      id: id!,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success add new card");
      fetchMember();
      setNewCard({
        cardNumber: "",
      });
    }
  }

  return (
    <div className="mt-12 mb-8 gap-12 min-h-[calc(100vh-200px)] grid grid-cols-2">
      <div className="col-span-1">
        <Card>
          <CardHeader
            variant="gradient"
            color="gray"
            className="p-6 flex items-center justify-between"
          >
            <Typography variant="h6" color="white">
              Member Detail
            </Typography>
          </CardHeader>
          <CardBody>
            <div className="flex flex-col gap-2">
              <div className="grid grid-cols-12">
                <div className="col-span-3">
                  <h5 className="f-h5">ID</h5>
                </div>
                <div className="col-span-9">
                  <h5 className="f-p1-r">{member.id}</h5>
                </div>
              </div>
              <div className="grid grid-cols-12">
                <div className="col-span-3">
                  <h5 className="f-h5">Name</h5>
                </div>
                <div className="col-span-9">
                  <h5 className="f-p1-r">{member.name}</h5>
                </div>
              </div>
              <div className="grid grid-cols-12">
                <div className="col-span-3">
                  <h5 className="f-h5">Email</h5>
                </div>
                <div className="col-span-9">
                  <h5 className="f-p1-r">{member.email}</h5>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        <br />
        <br />

        <Card>
          <form onSubmit={handleSubmit}>
            <CardHeader
              variant="gradient"
              color="gray"
              className="p-6 flex items-center justify-between"
            >
              <Typography variant="h6" color="white">
                Add New Card
              </Typography>
            </CardHeader>

            <CardBody>
              <div className="mt-0 flex flex-col gap-4">
                <InputDefaultComponent
                  label="Card Number"
                  name="cardNumber"
                  value={newCard.cardNumber}
                  handleChange={(e) => {
                    setNewCard({ ...newCard, cardNumber: e.target.value });
                  }}
                  autoFocus={true}
                />
              </div>

              <div className="mt-8 flex gap-4 justify-center">
                <Button
                  size="sm"
                  color="gray"
                  variant="outlined"
                  onClick={() => navigate("/dashboard/member")}
                  className="w-full"
                >
                  Cancel
                </Button>

                <Button size="sm" color="gray" type="submit" className="w-full">
                  Add Card
                </Button>
              </div>
            </CardBody>
          </form>
        </Card>
      </div>

      <div className="col-span-1">
        <Card>
          <CardHeader
            variant="gradient"
            color="gray"
            className="p-6 flex items-center justify-between"
          >
            <Typography variant="h6" color="white">
              Member Cards
            </Typography>
          </CardHeader>
          <CardBody>
            {member.memberCards &&
              member.memberCards.map((card: any, index) => {
                return (
                  <div key={index}>
                    <div className="flex flex-col gap-2">
                      <div className="grid grid-cols-12">
                        <div className="col-span-5">
                          <h5 className="f-h5">Card Number</h5>
                        </div>
                        <div className="col-span-7 flex gap-2">
                          <h5 className="f-p1-r">{card.card_number}</h5>
                          <button
                            className=""
                            onClick={() => {
                              navigate(
                                `/dashboard/member/detail/${member.id}/card/${card.card_number}`
                              );
                            }}
                          >
                            <FiEdit />
                          </button>
                        </div>
                      </div>
                      <div className="grid grid-cols-12">
                        <div className="col-span-5">
                          <h5 className="f-h5">Point</h5>
                        </div>
                        <div className="col-span-7">
                          <h5 className="f-p1-r">{card.point}</h5>
                        </div>
                      </div>
                    </div>
                    {member.memberCards.length - 1 !== index && (
                      <hr className="w-full h-0.5 bg-gray-800 rounded-full my-4" />
                    )}
                  </div>
                );
              })}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
