import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Typography,
} from "@material-tailwind/react";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ProductServices } from "src/services/ProductServices";
import { ProductCart, ProductList } from "src/types/init";
import { RupiahFormat } from "src/utils/RupiahFormat";
import { CartContext } from "src/context/CartContext";

export function MenuListPage() {
  const navigate = useNavigate();

  const productsServices = new ProductServices();

  const { addToCart } = useContext(CartContext);

  const keyHide = "[HIDDEN_PRODUCT]-";
  const limit = 500;

  const [products, setProducts] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [tempProducts, setTempProducts] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [offset, setOffset] = useState(0);

  const [productActive, setProductActive] = useState<ProductList | null>(null);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch(pOffset: number = 0) {
    const res = await productsServices.gets({
      limit: limit,
      offset: pOffset ?? offset,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const sortedBySubmenuId = res.data.sort(
        (a: any, b: any) => a.submenu_id - b.submenu_id
      );

      const temp = sortedBySubmenuId.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          price: item.price,
          originalPrice: item.original_price,
          stock: item.stock,
          displayPic: item.display_pic,
          digitalProduct: item.digital_product,
          submenuId: item.submenu_id,
        } as ProductList;
      });

      setProducts({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });

      setTempProducts({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });
    }
  }

  const handleSearch = (text: string) => {
    const filtered = tempProducts.data.filter((item: ProductList) =>
      item.name.toLowerCase().includes(text.toLowerCase())
    );

    setProducts({
      data: filtered,
      meta: {
        total: filtered.length,
      },
    });
  };

  async function handleHiddenProduct({
    id,
    name,
  }: {
    id: string;
    name: string;
  }) {
    let newName = "";
    if (name.includes(keyHide)) {
      newName = name.replace(keyHide, "").trim();
    } else {
      newName = `${keyHide}${name}`;
    }

    const res = await productsServices.hiddenProduct({ name: newName, id: id });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      fetch(offset * limit);
      toast.success("Success hide / show product");
    }
  }

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12 min-h-[calc(100vh-200px)]">
      <div className="grid grid-cols-12 gap-6">
        {productActive && (
          <DialogDefault
            product={productActive}
            handleCancel={() => {
              setProductActive(null);
            }}
            handleAdd={(temp: ProductCart) => {
              setProductActive(null);
              addToCart(temp);
              toast.success("Success add to cart");
            }}
          />
        )}

        <div className="col-span-12">
          <Input
            crossOrigin={""}
            label="Search"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>

        {products.data.map((item: ProductList) => {
          if (item.name.includes(keyHide)) return null;

          return (
            <button
              key={item.id}
              className="mt-6 col-span-3 group"
              onClick={() => {
                <Button size="lg" type="submit">
                  Submit
                </Button>;
                setProductActive(item);
              }}
            >
              <Card className="group-hover:bg-gray-300">
                <CardHeader color="blue-gray" className="relative h-36">
                  <img
                    src={item.displayPic}
                    alt="card-image"
                    className="object-cover w-full h-full rounded-lg"
                  />
                </CardHeader>
                <CardBody className="h-fit">
                  <Typography variant="h6" color="blue-gray" className="mb-1">
                    {item.name}
                  </Typography>
                  <Typography variant="h6" color="orange" className="mb-1">
                    {RupiahFormat(item.price)}
                  </Typography>
                  <Typography className="text-sm">
                    {item.description.length > 50
                      ? item.description.substring(0, 50) + "..."
                      : item.description}
                  </Typography>
                </CardBody>
              </Card>
            </button>
          );
        })}
      </div>
    </div>
  );
}

function DialogDefault({
  product,
  handleCancel,
  handleAdd,
}: {
  product: ProductList | null;
  handleCancel: () => void;
  handleAdd: (temp: ProductCart) => void;
}) {
  const [count, setCount] = useState(1);
  const [note, setNote] = useState("");

  return (
    <div className="bg-black bg-opacity-50 fixed top-0 left-0 h-full w-full z-50 flex justify-center items-center">
      <Card className="group-hover:bg-gray-300 flex flex-row">
        <CardHeader color="blue-gray" className="relative h-64">
          <img
            src={product!.displayPic}
            alt="card-image"
            className="object-cover w-full h-full rounded-lg"
          />
        </CardHeader>
        <CardBody className="h-fit">
          <Typography variant="h6" color="blue-gray" className="mb-1">
            {product!.name}
          </Typography>
          <Typography variant="h6" color="orange" className="mb-1">
            {RupiahFormat(product!.originalPrice)}
          </Typography>
          <Typography className="text-sm mb-1">
            Stock : {product!.stock > 1000 ? "Unlimited" : product!.stock}
          </Typography>
          <Typography className="text-sm">{product!.description}</Typography>

          {product?.digitalProduct == null ? (
            <div className="flex items-center my-2 gap-4 mt-4">
              <Button
                onClick={() => {
                  if (count > 1) setCount(count - 1);
                }}
              >
                <FiMinus />
              </Button>
              <Input
                value={count}
                crossOrigin={count.toString()}
                label="qty"
                readOnly={true}
              />
              <Button
                onClick={() => {
                  if (product!.stock > count) setCount(count + 1);
                }}
              >
                <FiPlus />
              </Button>
            </div>
          ) : null}

          <div className="my-4">
            <Input
              value={note}
              crossOrigin={note.toString()}
              label="note"
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </div>

          <div className="flex gap-4">
            <Button
              variant="outlined"
              onClick={handleCancel}
              className="w-full"
            >
              Cancel
            </Button>

            <Button
              size="lg"
              className="w-full"
              onClick={() => {
                const temp = {
                  id: new Date().getTime().toString(),
                  product: product!,
                  qty: count,
                  note: note,
                  buy: true,
                } as ProductCart;

                handleAdd(temp);
              }}
            >
              Add to Cart
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
