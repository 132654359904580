export function InputImage({
  label,
  name,
  preview,
  handleChange,
  required = false,
}: {
  label: string;
  name: string;
  preview: string | null;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}) {
  return (
    <div className="flex flex-col">
      <label className="f-p1-r mb-1 text-neutral-1000">{label}</label>
      <input
        type="file"
        name={name}
        required={required}
        onChange={handleChange}
      />
      <div className="rounded-md mt-4">
        {preview == null ? null : <img src={preview} alt="preview" />}
      </div>
    </div>
  );
}
