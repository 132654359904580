import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { MemberServices } from "src/services/MemberServices";
import { Member } from "src/types/init";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
  Button,
  Input,
} from "@material-tailwind/react";
import {
  FiAlignLeft,
  FiArrowLeft,
  FiChevronLeft,
  FiChevronRight,
  FiEdit,
  FiEye,
  FiTrash,
} from "react-icons/fi";
import LoadComponent from "src/widgets/load";
import { DeleteActionComponent } from "./component/DeleteActionComponent";

export function MemberListPage() {
  const navigate = useNavigate();
  const membersServices = new MemberServices();
  const limit = 100;

  const [members, setMembers] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [tempMembers, setTempMembers] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [offset, setOffset] = useState(0);
  const [load, setLoad] = useState(true);
  const [memberActive, setMemberActive] = useState<Member | null>(null);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch(pOffset: number | null = null) {
    setLoad(true);
    const res = await membersServices.gets({
      limit: limit,
      offset: pOffset ?? offset,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item: Member) => {
        return {
          id: item.id,
          name: item.name,
          email: item.email,
        };
      });
      setMembers({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });

      setTempMembers({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });
    }
    setLoad(false);
  }

  async function handleDelete(id: string) {
    setMemberActive(null);

    const res = await membersServices.delete({ id: id });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success delete member");
      fetch();
    }
  }

  const handleSearch = (text: string) => {
    const filteredData = tempMembers.data.filter((el: Member) =>
      el.name.toLowerCase().includes(text.toLowerCase())
    );

    setMembers({
      data: filteredData,
      meta: {
        total: filteredData.length,
      },
    });
  };

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12 min-h-[calc(100vh-200px)]">
      <Card>
        <CardHeader
          variant="gradient"
          color="gray"
          className="mb-8 p-6 flex items-center justify-between"
        >
          <Typography variant="h6" color="white">
            Member Table
          </Typography>
          <Button
            size="sm"
            color="white"
            onClick={() => {
              navigate("/dashboard/member/add");
            }}
          >
            Add Member
          </Button>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          <div className="mx-6 mb-6 mt-2">
            <Input
              crossOrigin={""}
              label="Search"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>

          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {["id", "name", "email", "action"].map((el) => (
                  <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-5 text-left"
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-bold uppercase text-blue-gray-400"
                    >
                      {el}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {members.data.map((item: Member, index: number) => {
                const className = `py-3 px-5 ${
                  parseInt(item.id) == members.data.length - 1
                    ? ""
                    : "border-b border-blue-gray-50"
                }`;

                return (
                  <tr key={index}>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {item.id}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {item.name}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {item.email}
                      </Typography>
                    </td>
                    <td className={`${className} flex gap-1`}>
                      <Button
                        size="sm"
                        onClick={() => {
                          navigate(`/dashboard/member/detail/${item.id}`);
                        }}
                      >
                        <FiEye />
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => {
                          navigate(`/dashboard/member/edit/${item.id}`);
                        }}
                      >
                        <FiEdit />
                      </Button>
                      <Button
                        size="sm"
                        color="red"
                        onClick={() => {
                          setMemberActive(item);
                        }}
                      >
                        <FiTrash />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="flex justify-end my-4 mr-4">
            <Pagination
              totalItems={members.meta.total}
              itemsPerPage={limit}
              index={offset}
              action={(index: number) => {
                fetch((index - 1) * limit);
              }}
            />
          </div>
        </CardBody>
      </Card>

      {load && <LoadComponent />}

      {memberActive != null && (
        <DeleteActionComponent
          member={memberActive}
          callback={async (id: string) => {
            handleDelete(id);
          }}
          cancel={() => {
            setMemberActive(null);
          }}
        />
      )}
    </div>
  );
}

const Pagination = ({
  totalItems,
  itemsPerPage,
  index,
  action,
}: {
  totalItems: number;
  itemsPerPage: number;
  index: number;
  action: (index: number) => void;
}) => {
  const [currentPage, setCurrentPage] = useState(index + 1);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    action(page);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav aria-label="Page navigation example">
      <ul className="flex items-center gap-2">
        <li className="page-item">
          <Button
            className="h-10"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            size="sm"
          >
            <FiChevronLeft size={20} />
          </Button>
        </li>
        {pageNumbers.map((number: number) => {
          if (Math.abs(number - currentPage) < 3) {
            return (
              <li key={number} className="page-item">
                <Button
                  className="h-10"
                  onClick={() => handlePageChange(number)}
                  color={currentPage === number ? "black" : "white"}
                  size="sm"
                >
                  {number}
                </Button>
              </li>
            );
          }
        })}
        <li className="page-item">
          <Button
            className="h-10"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === 1}
            size="sm"
          >
            <FiChevronRight size={20} />
          </Button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
