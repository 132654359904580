import { Button, Typography } from "@material-tailwind/react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { MemberServices } from "src/services/MemberServices";
import { InputDefaultComponent } from "./component/InputDefaultComponent";
import { InputImage } from "./component/input-image";

export function MemberAddPage() {
  const navigate = useNavigate();
  const memberServices = new MemberServices();

  const [member, setMember] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    photo: null,
    nationalIdNumber: "",
  });

  const [preview, setPreview] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setMember((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e: any) => {
    const { name, files } = e.target;
    setMember((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
    setPreview(URL.createObjectURL(files[0]));
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();

    const res = await memberServices.registration({ ...member });

    setLoading(false);

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success add member");
      navigate("/member");
    }
  };

  return (
    <div className="flex flex-col gap-12 min-h-[calc(100vh-200px)]">
      <div className="bg-white shadow-lg rounded-xl p-10">
        <Typography variant="h6" color="gray">
          Add Member
        </Typography>
        <form className="mt-8 mb-2" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 lg:gap-10 gap-5">
            <div className="mb-1 flex flex-col gap-5 lg:col-span-1 col-span-2">
              <InputDefaultComponent
                label="Fullname"
                name="name"
                value={member.name}
                handleChange={handleChange}
              />

              <InputDefaultComponent
                label="Email"
                name="email"
                value={member.email}
                handleChange={handleChange}
              />

              <InputDefaultComponent
                label="Password"
                name="password"
                value={member.password}
                handleChange={handleChange}
              />

              <InputDefaultComponent
                label="Phone"
                name="phone"
                value={member.phone}
                handleChange={handleChange}
              />

              <InputDefaultComponent
                label="National ID Number"
                name="nationalIdNumber"
                value={member.nationalIdNumber}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-1 flex flex-col gap-5 lg:col-span-1 col-span-2">
              <InputImage
                label="Photo"
                name="photo"
                preview={preview}
                handleChange={handleFileChange}
                required={true}
              />
            </div>
          </div>

          <div className="flex mt-8 gap-4">
            <Button size="lg" type="submit">
              Submit
            </Button>

            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
