import { Route, Routes } from "react-router-dom";
import { MemberDetailPage } from "./detail";
import { MemberListPage } from "./list";
import { MemberAddPage } from "./add";
import { MemberEditPage } from "./edit";
import { MemberEditCardPage } from "./edit-card";

export function MemberRouter() {
  return (
    <div className="mt-4 min-h-[calc(100vh-160px)]">
      <Routes>
        <Route path="/" element={<MemberListPage />} />
        <Route path="/add" element={<MemberAddPage />} />
        <Route path="/detail/:id" element={<MemberDetailPage />} />
        <Route path="/edit/:id" element={<MemberEditPage />} />
        <Route
          path="/detail/:id/card/:cardNumber"
          element={<MemberEditCardPage />}
        />
      </Routes>
    </div>
  );
}
