import { apiUrl } from "src/configs/url";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";
import axios from "axios";
import { headers, headersAuth, headersFormData } from "./config";

export class ProductServices {
  async editStock({ stock, id }: { stock: number; id: string }) {
    try {
      const res = await axios.patch(
        `${apiUrl}/dashboard/products/${id}`,
        { stock },
        {
          headers: headersAuth,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async get({ id }: { id: string }) {
    try {
      const res = await axios.get(`${apiUrl}/dashboard/products/${id}`, {
        headers,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async gets({ limit = 20, offset = 0 }) {
    try {
      const res = await axios.get(
        `${apiUrl}/dashboard/products?limit=${limit}&offset=${offset}`,
        { headers }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async hiddenProduct({ name, id }: { name: string; id: string }) {
    try {
      const res = await axios.patch(
        `${apiUrl}/dashboard/products/${id}`,
        {
          name,
        },
        {
          headers: headersAuth,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
